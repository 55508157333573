import React, {useEffect, useState} from 'react'
import 'leaflet/dist/leaflet.css'
import {MapContainer} from 'react-leaflet/MapContainer'
import {TileLayer} from 'react-leaflet/TileLayer'
import DepotMarker from './DepotMarker'
import {TileLayers} from './TileLayers'
import LayersControlComponent from "./LayersControl";
import {ZoomControl} from "react-leaflet";
import MapOverlaySolutionButtons from "./MapOverlay/MapOverlaySolutionButtons";
import {generateColors, getProblemBounds} from "./Utils";

const SolutionMap = ({problem, solution}) => {
    const [layerGroups, setLayerGroups] = useState([])

    const bounds = getProblemBounds(problem)
    const colors = generateColors(solution.routes.length, false)

    const routeItemIsDepot = (routeItem) => {
        return routeItem.depot
    }

    const routeItemIsCustomer = (routeItem) => {
        return routeItem.customer
    }

    const routeItemIsPause = (routeItem) => {
        return routeItem.label.toLowerCase() === 'pause'
    }

    const getRouteItemLocation = (routeItem) => {
        if (routeItemIsDepot(routeItem)) {
            return [routeItem.depot.location.lat, routeItem.depot.location.long]
        } else if (routeItemIsCustomer(routeItem)) {
            return [routeItem.customer.location.lat, routeItem.customer.location.long]
        }
    }

    const getLayerGroups = () => {
        const layerGroupsData = []

        for (const [index, route] of solution.routes.entries()) {
            const customersData = []
            const polylines = []

            const color = colors[index]

            // Customers
            for (const [index, routeItem] of route.route_items.entries()) {
                if (routeItemIsDepot(routeItem) || routeItemIsPause(routeItem)) {
                    continue
                }

                customersData.push({
                    routeItem: routeItem,
                    color: color,
                    stopNumber: index
                })
            }

            // Polylines
            for (let i = 1; i < route.route_items.length; i++) {
                let fromRouteItem = route.route_items[i - 1]
                const toRouteItem = route.route_items[i]

                if (toRouteItem.label.toLowerCase() === 'pause') {
                    continue
                }

                if (fromRouteItem.label.toLowerCase() === 'pause') {
                    fromRouteItem = route.route_items[i - 2]
                }

                const from = getRouteItemLocation(fromRouteItem)
                const to = getRouteItemLocation(toRouteItem)

                let opacity = 1.0
                if (routeItemIsDepot(fromRouteItem) || routeItemIsDepot(toRouteItem)) {
                    opacity = 1.0
                }

                polylines.push({
                    color,
                    opacity,
                    from,
                    to
                })
            }

            layerGroupsData.push({
                name: route.vehicle.label,
                route,
                customersData,
                polylines
            })
        }

        return layerGroupsData
    }

    useEffect(() => {
        setLayerGroups(getLayerGroups())
    }, [])

    return (
        <>
            {layerGroups.length === 0 || bounds.length === 0
                ? <div/>
                : <MapContainer
                    bounds={bounds}
                    boundsOptions={{padding: [100, 100]}}
                    scrollWheelZoom={true}
                    attributionControl={false}
                    zoomControl={false}
                    className="full-map-container"
                >
                    <TileLayer url={TileLayers.CartoDB_DarkMatter}/>
                    <ZoomControl position={'topleft'}/>
                    <LayersControlComponent layerGroups={layerGroups}/>


                    {problem.depots.map((depot, depotIndex) => {
                        return <DepotMarker key={depotIndex} depot={depot}/>
                    })}

                    <MapOverlaySolutionButtons problem={problem} solution={solution}/>
                    {/*<MapOverlayRoutes/>*/}

                </MapContainer>
            }
        </>
    )
}

export default SolutionMap
