import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Providers from './contexts/Providers'
import 'bootstrap/dist/css/bootstrap.min.css'
// import './styles/css/sb-admin-2.css';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <Providers>
            <App/>
        </Providers>
    </BrowserRouter>
)
