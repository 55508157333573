import React from 'react'
import 'leaflet/dist/leaflet.css'
import {MapContainer} from 'react-leaflet/MapContainer'
import {TileLayer} from 'react-leaflet/TileLayer'
import DepotMarker from './DepotMarker'
import {TileLayers} from './TileLayers'
import {Polyline, ZoomControl} from "react-leaflet";
import {
    getRouteBounds,
    getRoutePolylines,
    GREEN_COMPLETE_RGBA,
    ORANGE_HSL,
    routeItemIsComplete,
    routeItemIsCustomer,
} from "./Utils";
import SolutionCustomerMarker from "./SolutionCustomerMarker";


const RouteMap = ({route, setMap, bounds}) => {
    if (bounds === undefined || bounds === null) {
        bounds = getRouteBounds({route})
    }
    const color = ORANGE_HSL;

    let routeItemDepots = route.route_items.filter(routeItem => routeItem.depot);
    const uniqueRouteItemDepots = routeItemDepots.reduce(function (p, c) {
        // if the next object's id is not found in the output array
        // push the object into the output array
        if (!p.some(function (el) {
            return el.id === c.id;
        })) p.push(c);
        return p;
    }, []);

    return (
        <>
            {bounds.length === 0
                ? <div/>
                : <MapContainer
                    bounds={bounds}
                    ref={setMap}
                    scrollWheelZoom={true}
                    attributionControl={false}
                    zoomControl={false}
                    className="route-map-container"
                >
                    <TileLayer url={TileLayers.CartoDB_DarkMatter}/>
                    <ZoomControl position={'topleft'}/>

                    {uniqueRouteItemDepots.map((routeItemDepot, index) => {
                        return <DepotMarker key={index} depot={routeItemDepot.depot}/>
                    })}

                    {route.route_items.filter(routeItem => routeItemIsCustomer(routeItem)).map((routeItem, index) => {
                        let routeColor = color;
                        if (routeItemIsComplete(routeItem)) {
                            routeColor = GREEN_COMPLETE_RGBA;
                        }
                        return SolutionCustomerMarker({
                            key: index,
                            route,
                            routeItem,
                            color: routeColor,
                            stopNumber: index + 1
                        })
                    })}

                    {getRoutePolylines({routeItems: route.route_items}).map((pl, index) => {
                        return <Polyline
                            key={index}
                            positions={pl.location}
                            color={pl.color}
                            weight={2}
                            opacity={pl.opacity}
                        />
                    })}

                </MapContainer>
            }
        </>
    )
}

export default RouteMap
