import React from 'react'
import {Route, Routes} from 'react-router-dom'
import PrivateRoute from './utils/PrivateRoute'
import {AppRoutes} from './appRoutes'
import Layout from './utils/Layout'
import {HelmetProvider} from "react-helmet-async";

function App() {
    const MultiplePathsRoute = ({appRoute}) =>
        appRoute.paths.map((path) => <Route key={path} path={path} element={appRoute.element}/>);

    return (
        <HelmetProvider>
            <Routes>
                <Route path={AppRoutes.Login.path} element={AppRoutes.Login.element}/>

                <Route element={<Layout/>}>

                    {/* Private routes */}
                    <Route element={<PrivateRoute/>}>
                        {MultiplePathsRoute({appRoute: AppRoutes.Dashboard})}
                        {MultiplePathsRoute({appRoute: AppRoutes.UploadProblem})}
                        {MultiplePathsRoute({appRoute: AppRoutes.Problem})}
                        {MultiplePathsRoute({appRoute: AppRoutes.Problems})}
                        {MultiplePathsRoute({appRoute: AppRoutes.ShareRoutes})}
                        {MultiplePathsRoute({appRoute: AppRoutes.SolutionRoute})}
                        {MultiplePathsRoute({appRoute: AppRoutes.SolutionRoutes})}
                        {MultiplePathsRoute({appRoute: AppRoutes.Solution})}
                        {MultiplePathsRoute({appRoute: AppRoutes.Solutions})}
                        {MultiplePathsRoute({appRoute: AppRoutes.UserDrivers})}
                        {MultiplePathsRoute({appRoute: AppRoutes.UserSettings})}
                        {MultiplePathsRoute({appRoute: AppRoutes.UserProfile})}

                        {/* Error */}
                        {MultiplePathsRoute({appRoute: AppRoutes.ErrorPage})}

                        {/* Catch all */}
                        {MultiplePathsRoute({appRoute: AppRoutes.CatchAll})}
                    </Route>

                </Route>
            </Routes>
        </HelmetProvider>
    )
}

export default App
