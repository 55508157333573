import React from 'react'
import Dashboard from './components/DashboardPage/Dashboard'
import UploadProblem from './components/Problem/Upload/UploadProblem'
import Problem from './components/Problem/Problem'
import Login from './components/AddonPages/Login'
import ErrorPage from './components/AddonPages/ErrorPage'
import Problems from './components/Problems/Problems'
import Solution from './components/Solutions/Solution'
import UserSettings from './components/User/UserSettings'
import UserProfile from "./components/User/UserProfile";
import Solutions from "./components/Solutions/Solutions";
import ShareRoutes from "./components/Solutions/ShareRoutes";
import SolutionRoutes from "./components/Solutions/SolutionRoutes";
import SolutionRoute from "./components/Solutions/SolutionRoute";
import UserDrivers from "./components/User/UserDrivers";

export const AppRoutes = {
    // pages
    Dashboard: {
        element: <Dashboard/>,
        paths: ['/']
    },
    UploadProblem: {
        element: <UploadProblem/>,
        paths: ['/problems/upload']
    },
    Problem: {
        element: <Problem/>,
        paths: ['/problems/:problemId']
    },
    Problems: {
        element: <Problems/>,
        paths: ['/problems']
    },
    SolutionRoute: {
        element: <SolutionRoute/>,
        paths: ['p:problemId/s:solutionId/r:routeId', 'problems/:problemId/solutions/:solutionId/routes/:routeId']
    },
    SolutionRoutes: {
        element: <SolutionRoutes/>,
        paths: ['p:problemId/s:solutionId', 'problems/:problemId/solutions/:solutionId/routes']
    },
    ShareRoutes: {
        element: <ShareRoutes/>,
        paths: ['problems/:problemId/solutions/:solutionId/share']
    },
    Solution: {
        element: <Solution/>,
        paths: ['problems/:problemId/solutions/:solutionId']
    },
    Solutions: {
        element: <Solutions/>,
        paths: ['problems/:problemId/solutions']
    },
    Login: {
        element: <Login/>,
        path: '/login'
    },
    UserDrivers: {
        element: <UserDrivers/>,
        paths: ['/users/:id/drivers']
    },
    UserProfile: {
        element: <UserProfile/>,
        paths: ['/users/:id/profile']
    },
    UserSettings: {
        element: <UserSettings/>,
        paths: ['/users/:id/settings']
    },
    ErrorPage: {
        element: <ErrorPage/>,
        paths: ['/errorpage']
    },
    CatchAll: {
        element: <ErrorPage/>,
        paths: ['*']
    }
}
