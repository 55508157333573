import React, {useContext} from 'react'
import AuthContext from '../contexts/AuthProvider'
import {Button, Modal} from 'react-bootstrap'

const LogoutModal = ({
                         show,
                         setShow
                     }) => {
    const {logout} = useContext(AuthContext)

    const handleClose = () => setShow(false)
    const handleLogout = () => {
        handleClose()
        logout()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ready to Leave?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Logging out will end your current session.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className={"btn-orange shadow-none"} onClick={handleLogout}>
                    Logout
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default LogoutModal
