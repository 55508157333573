import React from 'react'
import {Marker, Tooltip} from 'react-leaflet'
import {divIcon} from 'leaflet'
import {renderToStaticMarkup} from 'react-dom/server'
import {toUtcHhMm} from "./Utils";


const SolutionCustomerMarker = ({route, routeItem, color, stopNumber}) => {
    /// https://www.geoapify.com/create-custom-map-marker-icon
    const getCustomerMarkerIcon = (color, stopNumber) => {
        const html = (
            <div className="custom-div-icon-sm">
                <div style={{background: color}} className="marker-pin-sm"/>
                <p style={{color}}>{stopNumber}</p>
            </div>
        )

        return divIcon({
            html: renderToStaticMarkup(html),
            iconSize: [30, 42],
            iconAnchor: [15, 42]
        })
    }

    return (
        <Marker
            key={routeItem.label}
            position={[routeItem.customer.location.lat, routeItem.customer.location.long]}
            icon={getCustomerMarkerIcon(color, stopNumber)}
            eventHandlers={{
                click: (e) => {
                    navigator.clipboard.writeText(routeItem.customer.label)
                },
            }}
        >
            <Tooltip style={{backgroundColor: color}}>
                <p className={'m-0'}><b>Route:</b> {route.vehicle.label}</p>
                <p className={'m-0'}><b>Id:</b> {routeItem.customer.label}</p>
                {routeItem && routeItem.arrival &&
                    <p className={'mt-3 mb-0'}><b>Arrival:</b> {toUtcHhMm(routeItem.arrival)}</p>}
                {routeItem && routeItem.serviceStart &&
                    <p className={'m-0'}><b>Service start:</b> {toUtcHhMm(routeItem.serviceStart)}</p>}
                {routeItem && routeItem.arrival &&
                    <p className={'m-0'}><b>Departure:</b> {toUtcHhMm(routeItem.departure)}</p>}
                {routeItem.customer.time_window &&
                    <p className={'mt-3 mb-0'}><b>Slot
                        start:</b> {toUtcHhMm(routeItem.customer.time_window.start)}</p>}
                {routeItem.customer.time_window &&
                    <p className={'m-0'}><b>Slot end:</b> {toUtcHhMm(routeItem.customer.time_window.end)}</p>}
            </Tooltip>
        </Marker>
    )
}

export default SolutionCustomerMarker
