import React, {useEffect, useState} from 'react'
import useAxiosPrivate from '../../adapters/xhr/axiosPrivate'
import {Badge, Card, Col, Container, ListGroup, Row} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import Heading from '../Heading'
import RoutePlannersInProgress from "../Problem/RoutePlannersInProgress/RoutePlannersInProgress";
import CenterSpinner from "../../utils/CenterSpinner";
import SEO from "../../utils/SEO";
import {pluralize} from "../Maps/Utils";

const Solutions = () => {
    const {problemId} = useParams()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [otherSolutions, setOtherSolutions] = useState([])
    const [bestChoiceSolution, setBestChoiceSolution] = useState(null);

    useEffect(() => {
        setIsLoading(true)
        axiosPrivate.get(`/v1/problems/${problemId}/solutions/frontend/minimal/`).then((response) => {
            if (response.status === 200) {
                // Set best choice solution
                let validSolutions = response.data.filter(solution => solution.summary.valid);
                let bestSolution;
                if (validSolutions.length > 0) { // Get the valid solution with the minimum number of vehicles
                    validSolutions.sort((a, b) => a.num_routes - b.num_routes || parseFloat(a.summary.total_cost) < parseFloat(b.summary.total_cost));
                    bestSolution = validSolutions[0];
                } else { // Get the invalid solution with the minimum cost
                    bestSolution = response.data.reduce((a, b) => parseFloat(a.summary.total_cost) < parseFloat(b.summary.total_cost) ? a : b);
                }
                setBestChoiceSolution(bestSolution);

                // Set other solutions
                response.data.sort((a, b) => (a.num_routes - b.num_routes))
                setOtherSolutions(response.data.filter(solution => solution.id !== bestSolution.id))
            }
            setIsLoading(false)
        }).catch(_error => {
            setIsLoading(false)
        })
    }, [])

    const sortOnName = () => {
        const sortedSolutions = [].concat(otherSolutions).sort((a, b) => ('' + a.name).localeCompare(b.name))
        setOtherSolutions(sortedSolutions)
    }

    const SolutionInfo = ({solution}) => {
        return (
            <>
                {/*Cost*/}
                <p className={'mb-0'}>
                    <small>
                        Cost: <b>{Number((parseFloat(solution.summary.total_cost)).toFixed(1))}</b>
                    </small>
                </p>

                {/*Feasibility*/}
                {solution.summary.valid
                    ? <Badge bg="success">Feasible</Badge>
                    : <Badge bg="danger">Not feasible</Badge>}

                {/*Number of routes*/}
                <Badge bg="secondary" className={'ms-1'}>
                    {solution.num_routes} route{pluralize(solution.num_routes)}
                </Badge>
            </>
        )
    }

    const SolutionListGroup = ({solution}) => {
        return (
            <ListGroup>
                {/*<h5>{solution.num_routes} route{pluralize(solution.num_routes)}</h5>*/}
                <ListGroup.Item
                    action
                    onClick={() => navigate(`/problems/${problemId}/solutions/${solution.id}`)}
                >
                    <SolutionInfo solution={solution}/>
                </ListGroup.Item>
            </ListGroup>
        )
    }

    return (
        <>
            <SEO title={"Solutions"}/>
            <Container fluid={false} className="my-4">
                {
                    isLoading
                        ? <CenterSpinner text={"Retrieving solutions.."}/>
                        : <>
                            <RoutePlannersInProgress problemId={problemId}/>
                            <Heading text="Solutions"/>

                            {
                                Object.keys(otherSolutions).length === 0 && !bestChoiceSolution
                                    ? <p className={"mt-1"}>No solutions exists for this problem..</p>
                                    : <div className={"mt-1"}>
                                        <Row className={"mb-3"}>
                                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <ListGroup>
                                                    <ListGroup.Item
                                                        action
                                                        onClick={() => navigate(`/problems/${problemId}/solutions/${bestChoiceSolution.id}`)}
                                                        className={"p-0"}
                                                    >
                                                        <Card className={"rounded-3 shadow-sm border-dark"}>
                                                            <Card.Header className={"py-3 border-dark text-center"}>
                                                                <small className={"text-muted font-10"}>We think this is
                                                                    your</small>
                                                                <h5 className={"my-0 fw-normal text-orange"}>Best Choice 🏆</h5>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <div className={""}>
                                                                    <SolutionInfo solution={bestChoiceSolution}/>
                                                                </div>

                                                            </Card.Body>
                                                        </Card>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>


                                        <Row>
                                            {otherSolutions.length > 0
                                                ?
                                                <>
                                                    <h5 className={"mt-4"}>Other options</h5>
                                                    {otherSolutions.map((solution, index) => (
                                                        <Col key={index} className={"mt-2"} xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            <SolutionListGroup solution={solution}/>
                                                        </Col>
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    <h5 className={"mt-4"}>Other options</h5>
                                                    <p>No other solutions..</p>
                                                </>
                                            }
                                        </Row>
                                    </div>
                            }
                        </>
                }
            </Container>
        </>
    )
}

export default Solutions
