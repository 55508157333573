import React, {useEffect, useState} from 'react'
import useAxiosPrivate from '../../adapters/xhr/axiosPrivate'
import {Link} from 'react-router-dom'
import {Col, Container, Dropdown, ListGroup, Row} from 'react-bootstrap'
import Heading from '../Heading'
import CenterSpinner from "../../utils/CenterSpinner";
import SEO from "../../utils/SEO";
import ProblemListGroupItem from "./ProblemListGroupItem";

const Problems = () => {
    const axiosPrivate = useAxiosPrivate()

    const [isProblemsLoading, setIsProblemsLoading] = useState(true)
    const [problems, setProblems] = useState([])

    useEffect(() => {
        setIsProblemsLoading(true)
        axiosPrivate.get('/v1/problems/frontend/minimal/').then((response) => {
            if (response.status === 200) {
                const data = _sortOnLatestFirst(response.data)
                setProblems(data)
            }
            setIsProblemsLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const _sortOnName = (data) => {
        return [].concat(data).sort((a, b) => ('' + a.name).localeCompare(b.name))
    }

    const sortOnName = () => {
        setProblems(_sortOnName(problems))
    }

    const sortOnEarliestFirst = () => {
        const sortedProblems = [].concat(problems).sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        setProblems(sortedProblems)
    }

    const _sortOnLatestFirst = (data) => {
        return [].concat(data).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }

    const sortOnLatestFirst = () => {
        setProblems(_sortOnLatestFirst(problems))
    }

    return (
        <Container fluid={true} className={"my-4"}>
            <SEO title={"Problems"}/>
            {
                isProblemsLoading
                    ? <CenterSpinner text={"Retrieving problems.."}/>
                    : <>
                        <Heading text="Problems"/>
                        {
                            Object.keys(problems).length === 0
                                ?
                                <p className={"mt-1"}>No problems found..
                                    <br/>
                                    <Link to={"/problems/upload"}>Upload one?</Link>
                                </p>
                                :
                                <>
                                    <Row className="mb-2">
                                        <Col className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle size="sm" className={"btn-orange shadow-none"}>
                                                    Sort by
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => sortOnName()}>
                                                        Name
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => sortOnEarliestFirst()}>
                                                        Date (earliest first)
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => sortOnLatestFirst()}>
                                                        Date (latest first)
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>

                                    <ListGroup>
                                        {problems.map((problem, index) => (
                                            <ProblemListGroupItem key={index} problem={problem} problems={problems}
                                                                  setProblems={setProblems}/>
                                        ))}
                                    </ListGroup>
                                </>
                        }
                    </>
            }
        </Container>
    )
}

export default Problems
