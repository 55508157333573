import React, {useEffect, useRef} from 'react'
import {FaTruck, FaUsers, FaWarehouse} from "react-icons/fa";
import L from "leaflet";
import {pluralize} from "../Utils";

const MapOverlayProblemSummary = ({problem}) => {

    const mapProblemSummaryStopPropagationRef = useRef(null);
    useEffect(() => {
        L.DomEvent.disableClickPropagation(mapProblemSummaryStopPropagationRef.current);
        L.DomEvent.disableScrollPropagation(mapProblemSummaryStopPropagationRef.current);
    }, [])

    return (
        <div ref={mapProblemSummaryStopPropagationRef} className={"map-problem-summary-container"}>
            <FaWarehouse
                className={"text-orange"}/><small> {problem.num_depots} depot{pluralize(problem.num_depots)}</small>
            <br/>
            <FaTruck
                className={"text-orange"}/><small> {problem.num_vehicles} vehicle{pluralize(problem.num_vehicles)}</small>
            <br/>
            <FaUsers
                className={"text-orange"}/><small> {problem.num_customers} stop{pluralize(problem.num_customers)}</small>
        </div>
    )
}

export default MapOverlayProblemSummary