import React, {useContext, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import AuthContext from '../../contexts/AuthProvider'
import axios from '../../adapters/xhr/axios'
import {Button, Form, Image, Spinner} from "react-bootstrap";
import Logo from '../../assets/images/logo/logo_vertical_orange.png'
import {MdError} from "react-icons/md";

const Login = () => {
    const {setTokenData} = useContext(AuthContext)

    const navigate = useNavigate()
    const location = useLocation()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = async (e) => {
        setIsLoading(true)
        setErrorMessage('')
        e.preventDefault()

        try {
            const response = await axios.post('/v1/login/',
                JSON.stringify({email, password}),
                {
                    withCredentials: false,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            setTokenData(response?.data)
            setEmail('')
            setPassword('')
            setIsLoading(false)
            navigate(location.state?.from || '/')
        } catch (error) {
            if (error.response.status === 400) {
                setErrorMessage('Invalid login credentials..')
            }
            setIsLoading(false)
        }
    }

    return (
        <div className={"bg-dark text-center d-flex flex-column min-vh-100 justify-content-center align-items-center"}>
            <div className={'form-login w-100 m-auto'}>
                <Form onSubmit={handleSubmit}>
                    <Image className={"mb-4"} src={Logo} width={"100"}/>
                    <Form.Group className={"mb-3"} controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className={"mb-3"} controlId="formBasicPassword">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Button className={"w-100 btn-orange"} type="submit" disabled={isLoading}>
                        Login

                        {isLoading ?
                            <Spinner className={"ms-2"} animation="border" role="status" size={"sm"}>
                                <span className="visually-hidden">Logging in..</span>
                            </Spinner>
                            :
                            <></>}
                    </Button>

                    <p className={"text-start text-light mt-3"}>Don't have an account?{' '}
                        <a
                            href="https://www.greengard.no/contact"
                            target={"_blank"}>
                            Get access now.
                        </a>
                    </p>

                    {errorMessage && <p className={"text-start text-red-100 mt-3"}>
                        <MdError className={"mb-1"}/> {errorMessage}
                    </p>}

                </Form>
            </div>
        </div>
    )
}

export default Login
