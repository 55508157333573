import React, {useContext} from "react";
import AuthContext from "../../contexts/AuthProvider";
import Heading from "../Heading";
import {Container} from "react-bootstrap";
import SEO from "../../utils/SEO";

const UserProfile = () => {
    const {getUserId, getUserEmail, getUserName} = useContext(AuthContext)

    return (
        <>
            <SEO title={"Profile"}/>
            <Container fluid={true} className={"my-4"}>
                <Heading text="Profile"/>

                <p>Id: {getUserId()}</p>
                <p>Email: {getUserEmail()}</p>
                <p>Name: {getUserName()}</p>
            </Container>
        </>
    )
}

export default UserProfile