import React, {useState} from 'react'
import {matchPath, Outlet, useLocation} from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Topbar from '../components/Topbar'
import Footer from "../components/Footer";
import {AppRoutes} from "../appRoutes";

const SIDEBAR_TOGGLE = 'sidebarToggle'


const Layout = () => {
    const location = useLocation()

    const getSidebarToggle = () => localStorage.getItem(SIDEBAR_TOGGLE) === "true"

    const [sidebarToggle, setSidebarToggle] = useState(getSidebarToggle())

    const onChangeSidebarToggle = () => {
        localStorage.setItem(SIDEBAR_TOGGLE, (!sidebarToggle).toString())
        setSidebarToggle(!sidebarToggle)
    }

    const matchesPaths = (paths) => {
        return paths.some(function (path) {
            return matchPath(
                {
                    path: path,
                    caseSensitive: false, // Optional, `true` == static parts of `path` should match case
                    end: true, // Optional, `true` == pattern should match the entire URL pathname
                },
                location.pathname
            )
        })
    }

    const excludeSidebar = () => {
        let paths = []
        paths.push(AppRoutes.Login.path);
        paths.push(...AppRoutes.SolutionRoutes.paths);
        paths.push(...AppRoutes.SolutionRoute.paths);

        return matchesPaths(paths)
    }

    const excludeTopbar = () => {
        let paths = []
        paths.push(AppRoutes.Login.path);
        paths.push(...AppRoutes.SolutionRoutes.paths);
        paths.push(...AppRoutes.SolutionRoute.paths);

        return matchesPaths(paths)
    }

    const includeFooter = () => {
        return [].includes(location.pathname)
    }

    return (
        <div>
            {/* <!-- Page Wrapper --> */}
            <div id="wrapper">

                {/* <!-- Sidebar --> */}
                {!excludeSidebar() && <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}
                                               onSetSidebarToggle={onChangeSidebarToggle}
                                               getSidebarToggle={getSidebarToggle}/>}
                {/* <!-- End of Sidebar --> */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/* Main Content */}
                    <div id="content">

                        {/* TopBar */}
                        {!excludeTopbar() && <Topbar onSetSidebarToggle={onChangeSidebarToggle}/>}
                        {/* End of TopBar */}

                        {/* Begin Page Content */}
                        <Outlet/>
                        {/* End Page Content */}

                    </div>
                    {/* <!-- End of Main Content --> */}

                    {/* <!-- Footer --> */}
                    {includeFooter() && <Footer/>}
                    {/* <!-- End of Footer --> */}

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            {/* <!-- End of Page Wrapper --> */}
        </div>
    )
}

export default Layout
