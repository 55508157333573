import React from 'react'
import { AuthProvider } from './AuthProvider'

const Providers = ({ children }) => (
    <AuthProvider>
        {children}
    </AuthProvider>
)

export default Providers
