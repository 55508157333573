import {LayerGroup, LayersControl, Polyline} from "react-leaflet";
import SolutionCustomerMarker from "./SolutionCustomerMarker";
import React from "react";
import {TileLayers} from "./TileLayers";

const LayersControlComponent = ({layerGroups}) => {

    const mapsLayerGroup = () => {
        return (
            <LayerGroup>
                {{
                    "Light": TileLayers.CartoDB_Positron,
                    "Dark": TileLayers.CartoDB_DarkMatter
                }}
            </LayerGroup>
        )
    }

    const routesLayerGroup = (layerGroup) => {
        return (
            <LayerGroup>
                {
                    layerGroup.customersData.map((customerData, index) => {
                        return <SolutionCustomerMarker
                            key={index}
                            route={layerGroup.route}
                            routeItem={customerData.routeItem}
                            color={customerData.color}
                            stopNumber={customerData.stopNumber}
                        />
                    })
                }

                {
                    layerGroup.polylines.map((polyline, index) => {
                        return (
                            <Polyline
                                key={index}
                                positions={[polyline.from, polyline.to]}
                                color={polyline.color}
                                weight={2}
                                opacity={polyline.opacity}
                            />
                        )
                    })
                }

            </LayerGroup>
        )
    }

    return (
        <LayersControl>
            {
                layerGroups.map((layerGroup, index) => {
                    return (
                        <LayersControl.Overlay key={index} checked name={layerGroup.name}>
                            {routesLayerGroup(layerGroup)}
                        </LayersControl.Overlay>
                    )
                })
            }
        </LayersControl>
    )
}

export default LayersControlComponent