import React, {useEffect, useState} from 'react'
import useAxiosPrivate from '../../../adapters/xhr/axiosPrivate'
import Heading from '../../Heading'
import RoutePlannerInProgress from './RoutePlannerInProgress'
import {SolverRun} from '../../../models/SolverRun'

const RoutePlannersInProgress = ({problemId}) => {
    const axiosPrivate = useAxiosPrivate()
    const [solverRuns, setSolverRuns] = useState([])

    const fetchSolverRuns = () => {
        axiosPrivate.get(`/v1/problems/${problemId}/solver-runs/`).then((response) => {
            if (response.status === 200) {
                const solverRunsFromBackend = []
                for (const sr of response.data) {
                    const solverRun = SolverRun(sr.start_time_unix_ms, sr.execution_time)
                    solverRunsFromBackend.push(solverRun)
                }
                setSolverRuns(solverRunsFromBackend)
            }
        }).catch(error => {
            if (error.response.status === 404) {
                setSolverRuns([])
            }
        })
    }

    useEffect(() => {
        fetchSolverRuns()
    }, [])

    useEffect(() => {
        const originalLength = solverRuns.length
        const intervalId = setInterval(() => {
            if (solverRuns.length !== originalLength) {
                clearInterval(intervalId)
            } else {
                fetchSolverRuns()
            }
        }, 2000) // Fetch solver runs every 2 second
        setTimeout(() => {
            clearInterval(intervalId)
        }, 6000) // Stop after 6 seconds
        return () => clearInterval(intervalId)
    }, [])

    return (
        solverRuns.length > 0 &&
        <div className="mb-5">
            <Heading text={'Route Planners In Progress'}/>
            {solverRuns.map((solverRun, index) => (
                <RoutePlannerInProgress key={index} index={index} solverRun={solverRun}/>
            ))}
        </div>
    )
}

export default RoutePlannersInProgress
