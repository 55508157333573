import moment from "moment";

export const ORANGE_HSL = 'hsl(33, 100%, 50%)';
export const GREEN_COMPLETE_RGBA = 'rgba(25, 135, 84, 1.0)';

export const getProblemBounds = (problem) => {
    const bounds = []
    problem.depots.map(depot => bounds.push([depot.location.lat, depot.location.long]))
    problem.customers.map(customer => bounds.push([customer.location.lat, customer.location.long]))
    return bounds
}

export const getRoutesBounds = ({routes}) => {
    return routes.map(route => getRouteBounds({route}))
}

export const getRouteBounds = ({route}) => {
    return route.route_items.map(routeItem => {
        if (routeItem.customer) {
            return [routeItem.customer.location.lat, routeItem.customer.location.long]
        } else if (routeItem.depot) {
            return [routeItem.depot.location.lat, routeItem.depot.location.long]
        }
    });
}

// https://stackoverflow.com/questions/70420753/getting-multiple-distinct-colors
export const generateColors = (numColors, shuffle = false) => {
    const colors = []

    // Remove 'numColors - 1' if removing 'colors.push(ORANGE_HSL)'
    colors.push(ORANGE_HSL)
    for (let i = 0; i < numColors - 1; i++) {
        colors.push(`hsl(${(360 / numColors) * (numColors - i)}, 80%, 50%)`)
    }

    if (shuffle) {
        // uses the Fisher-Yates Shuffle to shuffle the colours
        let currentIndex = colors.length
        let randomIndex

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex--;
            [colors[currentIndex], colors[randomIndex]] = [colors[randomIndex], colors[currentIndex]]
        }
    }

    return colors
}

export const routeItemIsDepot = (routeItem) => {
    return routeItem.depot
}

export const routeItemIsCustomer = (routeItem) => {
    return routeItem.customer
}

export const routeItemIsComplete = (routeItem) => {
    return routeItem.completed_at
}

export const toUtcHhMm = (iso8601String) => {
    return moment(iso8601String).utc().format('hh:mm');
}

export const getRouteItemLocation = (routeItem) => {
    if (routeItemIsDepot(routeItem)) {
        return [routeItem.depot.location.lat, routeItem.depot.location.long]
    } else if (routeItemIsCustomer(routeItem)) {
        return [routeItem.customer.location.lat, routeItem.customer.location.long]
    }
}

export const pluralize = (number) => {
    return number === 1 ? "" : "s"
}

export const getRoutePolylines = ({routeItems}) => {
    const polylines = []
    for (let i = 1; i < routeItems.length; i++) {
        let fromRouteItem = routeItems[i - 1]
        const toRouteItem = routeItems[i]

        if (toRouteItem.label.toLowerCase() === 'pause') {
            continue
        }

        if (fromRouteItem.label.toLowerCase() === 'pause') {
            fromRouteItem = routeItems[i - 2]
        }

        const fromLocation = getRouteItemLocation(fromRouteItem)
        const toLocation = getRouteItemLocation(toRouteItem)

        let color;
        let opacity;
        if (toRouteItem.completed_at) {
            color = GREEN_COMPLETE_RGBA;
            opacity = 0.5;
        } else {
            color = ORANGE_HSL;
            opacity = 1.0;
        }

        polylines.push({
            polylineId: 'from-' + fromRouteItem.id + 'to-' + toRouteItem.id,
            color,
            opacity,
            location: [fromLocation, toLocation],
        })
    }
    return polylines
}