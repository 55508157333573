import MapOverlayHeader from "../Maps/MapOverlay/MapOverlayHeader";
import {useLocation} from "react-router-dom";
import {Button, Container, Form, InputGroup} from "react-bootstrap";
import {FaCopy} from "react-icons/fa";
import SEO from "../../utils/SEO";


const ShareRoutes = () => {
    const {state} = useLocation();
    const {problem, solution} = state;

    const linkValue = `app.greengard.no/p${problem.id}/s${solution.id}`;

    return (
        <>
            <SEO title={"Share"}/>
            <div className={"full-map-container"}>
                <MapOverlayHeader problem={problem} solution={solution} header={"Share routes"}/>

                <Container fluid={true} className={"mt-4"}>
                    <Form.Label className={"mb-1"}>Copy link and send to your drivers:</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control value={linkValue} disabled={true}/>
                        <Button
                            className={"btn-orange shadow-none"}
                            onClick={() => {
                                navigator.clipboard.writeText(linkValue)
                            }}
                        >
                            <FaCopy/> Copy
                        </Button>
                    </InputGroup>
                </Container>
            </div>
        </>
    )
}

export default ShareRoutes