import React from 'react'
import {Marker, Tooltip} from 'react-leaflet'
import {divIcon} from 'leaflet'
import {renderToStaticMarkup} from 'react-dom/server'
import {toUtcHhMm} from "./Utils";


const ProblemCustomerMarker = ({customer, color}) => {
    /// https://www.geoapify.com/create-custom-map-marker-icon
    const getCustomerMarkerIcon = (color) => {
        const html = (
            <div className="custom-div-icon-sm">
                <div style={{background: color}} className="marker-pin-sm"/>
            </div>
        )

        return divIcon({
            html: renderToStaticMarkup(html),
            iconSize: [30, 42],
            iconAnchor: [15, 42]
        })
    }

    return (
        <Marker
            position={[customer.location.lat, customer.location.long]}
            icon={getCustomerMarkerIcon(color)}
            eventHandlers={{
                click: (e) => {
                    navigator.clipboard.writeText(customer.label)
                },
            }}
        >
            <Tooltip style={{backgroundColor: color}}>
                <p className={'m-0'}><b>Id:</b> {customer.label}</p>

                {customer.time_window &&
                    <p className={'mt-3 mb-0'}>
                        <b>Slot start:</b> {toUtcHhMm(customer.time_window.start)}
                    </p>
                }

                {customer.time_window &&
                    <p className={'m-0'}>
                        <b>Slot end:</b> {toUtcHhMm(customer.time_window.end)}
                    </p>
                }
            </Tooltip>
        </Marker>
    )
}

export default ProblemCustomerMarker
