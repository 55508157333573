import React, {useEffect, useState} from 'react'
import useAxiosPrivate from '../../adapters/xhr/axiosPrivate'
import {useParams} from 'react-router-dom'
import Heading from '../Heading'
import ProblemMap from "../Maps/ProblemMap";
import CenterSpinner from "../../utils/CenterSpinner";
import SEO from "../../utils/SEO";

const Problem = () => {
    const {problemId} = useParams()
    const axiosPrivate = useAxiosPrivate()

    const [isLoading, setIsLoading] = useState(true)
    const [problem, setProblem] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        axiosPrivate.get(`/v1/problems/frontend/simple/${problemId}/`).then((response) => {
            if (response.status === 200) {
                setProblem(response.data)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            <SEO title={"Problem"}/>
            {isLoading
                ? <CenterSpinner text={"Retrieving problem.."}/>
                : problem
                    ? <>
                        <ProblemMap problem={problem}/>
                    </>
                    :
                    <>
                        <Heading text="Problem not found"/>
                        <p>{`No problem with id ${problemId} found..`}</p>
                    </>
            }
        </>

    )
}

export default Problem
