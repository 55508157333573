import {Badge, ListGroup, Spinner} from "react-bootstrap";
import {FaTrash, FaTruck, FaUsers, FaWarehouse} from "react-icons/fa";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import {pluralize} from "../Maps/Utils";

const ProblemListGroupItem = ({problem, problems, setProblems}) => {
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const onDelete = (event, problemId) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        setIsLoading(true)
        axiosPrivate.get(`/v1/problems/${problemId}/delete/`).then((response) => {
            if (response.status === 200) {
                setProblems(problems.filter(p => p.id !== problemId))
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            setIsLoading(false)
        })
    }

    return (
        <ListGroup.Item action
                        className={"d-flex justify-content-between align-items-center mb-2"}
                        onClick={() => navigate(`/problems/${problem.id}`)}>
            <div>
                <p className="mb-0 font-weight-bold font-12">{problem.name}</p>


                <Badge className={"bg-dark-gray"}>
                    <FaWarehouse
                        className={"text-orange"}/><small
                    className={"font-12"}> {problem.num_depots} depot{pluralize(problem.num_depots)}</small>
                </Badge>

                <Badge className={"bg-dark-gray ms-1"}>
                    <FaTruck
                        className={"text-orange"}/><small
                    className={"font-12"}> {problem.num_vehicles} vehicle{pluralize(problem.num_vehicles)}</small>
                </Badge>

                <Badge className={"bg-dark-gray ms-1"}>
                    <FaUsers
                        className={"text-orange"}/><small
                    className={"font-12"}> {problem.num_customers} stop{pluralize(problem.num_customers)}</small>
                </Badge>

                <br/>

                <small
                    className="text-gray-600 font-11"
                >
                    {new Date(problem.created_at).toLocaleString('nb-NO')}
                </small>

            </div>

            {isLoading ?
                <Spinner animation="border" role="status" size={"sm"}>
                    <span className="visually-hidden"/>
                </Spinner>
                :
                <FaTrash onClick={(event) => onDelete(event, problem.id)}/>}
        </ListGroup.Item>
    )
}

export default ProblemListGroupItem