import {Button, Container, ListGroup, Tab, Tabs} from "react-bootstrap";
import Heading from "../Heading";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import CenterSpinner from "../../utils/CenterSpinner";
import NotAuthorized from "../../utils/NotAuthorized";
import {AiOutlineOrderedList} from "react-icons/ai";
import {BsMap} from "react-icons/bs";
import {FaCog, FaSignOutAlt} from "react-icons/fa";
import AuthContext from "../../contexts/AuthProvider";
import RoutesMap from "../Maps/RoutesMap";
import {getRoutesBounds} from "../Maps/Utils";

const SolutionRoutes = () => {
    const {problemId, solutionId} = useParams()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const {getUserName, logout} = useContext(AuthContext)

    const [isLoading, setIsLoading] = useState(true)
    const [problem, setProblem] = useState(null)
    const [routes, setRoutes] = useState(null)
    const [map, setMap] = useState(null);
    const [bounds, setBounds] = useState(null);

    const _sortRoutesOnVehicleLabel = (data) => {
        return data.sort((a, b) => ('' + a.vehicle.label).localeCompare(b.vehicle.label))
    }

    const fetchData = async () => {
        setIsLoading(true);

        const problemUrl = `/v1/problems/frontend/simple/${problemId}/`;
        const routesUrl = `/v1/problems/${problemId}/solutions/${solutionId}/routes/`;

        const responses = await Promise.all([axiosPrivate.get(problemUrl), axiosPrivate.get(routesUrl)]).catch(error => {
            console.log(error)
            setIsLoading(false);
        })

        const problemData = await responses[0].data
        let routesData = await responses[1].data
        _sortRoutesOnVehicleLabel(routesData)

        setProblem(problemData)
        setRoutes(routesData)
        setBounds(getRoutesBounds({routes: routesData}))

        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [])

    const getListGroupItem = ({index, route}) => {
        return <ListGroup.Item
            key={index}
            action
            onClick={() => navigate(`/p${problemId}/s${solutionId}/r${route.id}`)}
        >
            {route.vehicle.label}
        </ListGroup.Item>
    }

    const onTabSelect = (key) => {
        if (key === "map") {
            setTimeout(() => {
                map.invalidateSize();
                map.fitBounds(bounds, {
                    padding: [100, 100],
                    maxZoom: 14
                });
            }, 0);
        }
    }

    return (
        isLoading
            ? <CenterSpinner text={"Retrieving routes.."}/>
            : problem && routes
                ?
                <div className={"solution-routes-container"}>
                    <Tabs
                        defaultActiveKey="list"
                        className={"fixed-top"}
                        justify
                        onSelect={(key) => onTabSelect(key)}
                        transition={false}
                    >
                        <Tab eventKey="list" title={<AiOutlineOrderedList/>} style={{marginTop: "60px"}}>
                            <Container fluid={false}>
                                <Heading text="Routes"/>
                                <ListGroup className={"mt-3"}>
                                    {routes.map((route, index) => {
                                        return getListGroupItem({index, route})
                                    })}
                                </ListGroup>
                            </Container>
                        </Tab>

                        <Tab eventKey="map" title={<BsMap/>} style={{marginTop: "41px"}}>
                            <Container fluid={false} className={"mt-5 pt-3"}>
                                <RoutesMap routes={routes} setMap={setMap} bounds={bounds}/>
                            </Container>
                        </Tab>

                        <Tab eventKey="settings" title={<FaCog/>} style={{marginTop: "60px"}}>
                            <Container fluid={false}>
                                <div className={"text-center"}>
                                    {getUserName()}
                                </div>

                                <div className={"text-center"}>
                                    <Button className={"btn-orange shadow-none mt-3"} style={{width: '110px'}}
                                            onClick={() => logout()}>
                                        <FaSignOutAlt className={"me-2"} style={{marginBottom: '0.1rem'}}/>
                                        Logout
                                    </Button>
                                </div>
                            </Container>
                        </Tab>
                    </Tabs>
                </div>
                :
                <NotAuthorized/>
    )
}

export default SolutionRoutes