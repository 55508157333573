import React, {useState} from 'react'
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../../adapters/xhr/axiosPrivate";
import {useDropzone} from "react-dropzone";
import {Card, Col, Container, Image, Row, Spinner} from "react-bootstrap";
import {SiGooglesheets} from "react-icons/si";
import ServerPushUndraw from "../../../assets/images/undraw/server_push.svg";
import Heading from "../../Heading";
import SEO from "../../../utils/SEO";

const UploadProblem = () => {
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    const onDrop = (acceptedFiles, rejectedFiles) => {
        const fileToUpload = acceptedFiles[0]
        setIsLoading(true)

        const url = '/v1/problems/upload/'
        const formData = new FormData()
        formData.append('problem_file', fileToUpload)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axiosPrivate.post(url, formData, config).then((response) => {
            const data = response.data
            setErrorMessages([])
            setIsLoading(false)
            navigate(`/problems/${data.problem_id}`)
        }).catch(error => {
            // Validation failed
            if (error.response.status === 400) {
                const errors = error.response.data.split(',')
                setErrorMessages(prevState => {
                    return prevState.concat(errors)
                })
            } else {
                setErrorMessages(["Error when uploading the file.. Please check if the file is corrupt."])
            }
            setIsLoading(false)
        })
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'application/json': ['.xls', '.xlsx', '.json']
        },
        maxFiles: 1,
        onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)
    })

    const downloadProblemTemplateXLSXFromGoogle = () => {
        window.open("https://docs.google.com/spreadsheets/d/1gsUhs52ph2YqdonnQYP3eYXRISc8y03f5_vqCnp45Gg/edit?usp=sharing", "_blank")
    }

    const downloadProblemTemplateXLSXFromBackend = () => {
        axiosPrivate.get('/v1/download-problem-template-xlsx/', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'problem_template.xlsx'
                link.click()
            }
        }).catch(error => {
            if (error.response.status === 404) {
                console.log(error)
            }
        })
    }

    return (
        <>
            <SEO title={"Upload"}/>
            <Container fluid={true} className={"my-4"}>
                <Heading text={"Upload"}/>

                <Row className={"mt-4"}>
                    <Col lg={8} className={"pb-4"}>
                        <Card className={"shadow-sm"}>
                            <Card.Header className={"py-3"}>
                                <h6 className={"m-0 font-weight-bold text-orange"}>
                                    Upload Problem File
                                </h6>
                            </Card.Header>
                            <Card.Body>

                                {isLoading
                                    ? <div className={"text-center"}>
                                        <Spinner className={"mt-3"} animation="border" role="status">
                                            <span className={"visually-hidden"}/>
                                        </Spinner>
                                        <p>Validating problem..</p>
                                    </div>
                                    : <div>
                                        <small>
                                            The file type must be either XLS, XLSX or JSON.
                                        </small>

                                        <div className={"mt-3"} {...getRootProps()}>
                                            <input
                                                className={"shadow - sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"}
                                                {...getInputProps()}
                                            />
                                            <div
                                                className={
                                                    "w-full p-2 border border-dashed border-gray-900 text-center " +
                                                    (isDragActive ? "bg-gray-400" : "bg-gray-200")
                                                }
                                            >
                                                <Image src={ServerPushUndraw} className={"mt-4"}
                                                       style={{maxHeight: "150px"}}/>
                                                <p className={"mt-4"}>Select or Drop the file here ...</p>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4}>
                        <Card className={"download-template-card shadow-sm"}>
                            <Card.Header className={"py-3"}>
                                <h6 className={"m-0 font-weight-bold text-orange"}>
                                    Just getting started?
                                </h6>
                            </Card.Header>
                            <Card.Body className={"d-flex align-items-center justify-content-center text-center"}>
                                <div onClick={downloadProblemTemplateXLSXFromBackend}
                                     className={"download-problem-template-div"}>
                                    <SiGooglesheets className={"mt-3 text-orange"}/>
                                    <p className={"mt-3"}>Download template</p>
                                </div>
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>

                {errorMessages.length > 0 && <Row className={"justify-content-center mt-4"}>
                    <Col xs={12} className={"text-center"}>
                        {errorMessages.map((errorMessage, index) => {
                            return <p key={index} className={"text-red-100"}>{errorMessage}</p>
                        })}
                    </Col>
                </Row>}

            </Container>
        </>
    )
}

export default UploadProblem
