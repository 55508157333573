import React from 'react'
import {Marker, Tooltip} from 'react-leaflet'
import {FaWarehouse} from 'react-icons/fa'
import {divIcon} from 'leaflet'
import {renderToStaticMarkup} from 'react-dom/server'

const DepotMarker = ({depot}) => {
  /// https://www.geoapify.com/create-custom-map-marker-icon
  const getDepotMarkerIcon = () => {
    const html = <div className="custom-div-icon">
      <div className="marker-pin bg-black"/>
      <p className="depot-marker"><FaWarehouse/></p></div>

    // return icon({
    //   iconUrl: DepotMarkerWhite,
    //   iconSize: [30, 32],
    //   iconAnchor: [5, 35]
    // })

    return divIcon({
      html: renderToStaticMarkup(html),
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    })
  }

  return (
      <Marker position={[depot.location.lat, depot.location.long]} icon={getDepotMarkerIcon()} zIndexOffset={1000}>
        <Tooltip>{depot.label}</Tooltip>
      </Marker>
  )
}

export default DepotMarker
