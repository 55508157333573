import {Badge, Button, Col, Container, ListGroup, Row, Tab, Tabs} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import {FiMapPin} from "react-icons/fi";
import {AiOutlineCheck, AiOutlineFieldTime, AiOutlineOrderedList} from "react-icons/ai";
import {BsMap} from "react-icons/bs";
import RouteMap from "../Maps/RouteMap";
import CenterSpinner from "../../utils/CenterSpinner";
import NotAuthorized from "../../utils/NotAuthorized";
import {FaCog, FaSignOutAlt, FaUndo} from "react-icons/fa";
import AuthContext from "../../contexts/AuthProvider";
import {BiArrowBack} from "react-icons/bi";
import Heading from "../Heading";
import {getRouteBounds, routeItemIsComplete, toUtcHhMm} from "../Maps/Utils";

const SolutionRoute = () => {
    const {problemId, solutionId, routeId} = useParams()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const {getUserName, logout} = useContext(AuthContext)

    const [spinnerMessage, setSpinnerMessage] = useState(null)
    const [route, setRoute] = useState(null)
    const [map, setMap] = useState(null);
    const [bounds, setBounds] = useState(null);

    const fetchData = async () => {
        setSpinnerMessage("Retrieving route..")

        const routeUrl = `/v1/problems/${problemId}/solutions/${solutionId}/routes/${routeId}/`;

        const responses = await Promise.all([axiosPrivate.get(routeUrl)]).catch(error => {
            console.log(error)
            setSpinnerMessage(null)
        })

        const routeData = await responses[0].data

        setRoute(routeData)
        setBounds(getRouteBounds({route: routeData}))
        setSpinnerMessage(null)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const onTabSelect = (key) => {
        if (key === "map") {
            setTimeout(() => {
                map.invalidateSize();
                map.fitBounds(bounds, {
                    padding: [100, 100],
                    maxZoom: 14
                });
            }, 0);
        }
    }

    const navigationOnClick = ({routeItem}) => {
        window.open("https://maps.google.com?q=" + routeItem.customer.location.lat + "," + routeItem.customer.location.long);
    }

    const changeCompletedAt = ({routeItem}) => {
        setSpinnerMessage("Updating route..")

        if (routeItem.completed_at) {
            routeItem.completed_at = null;
        } else {
            routeItem.completed_at = Date.now() / 1000;
        }

        axiosPrivate.put(`/v1/problems/${problemId}/solutions/${solutionId}/routes/${routeId}/update/`, {
            route_item: routeItem
        }).then((response) => {
            if (response.status === 200) {
                setRoute(route.map(ri => {
                    if (ri.id === routeItem.id) {
                        return {
                            ...ri,
                            completed_at: routeItem.completed_at
                        }
                    } else {
                        return ri
                    }
                }));
            }
            setSpinnerMessage(null)
        }).catch(_error => {
            setSpinnerMessage(null)
        })
    }


    const getCompleteListGroupItem = ({routeItemIndex, routeItem}) => {
        return <ListGroup.Item
            key={routeItemIndex + 1}
            className={"mb-2 shadow-sm"}
            style={{backgroundColor: "rgba(25, 135, 84, 0.3)"}}
        >
            <Row>
                <Col xs={10}>
                    <Badge className={"bg-orange"} pill>{routeItemIndex + 1}</Badge>

                    <small
                        className={"me-auto ms-2 font-13"}>{routeItem.customer.name ? routeItem.customer.name : routeItem.label}
                    </small>
                </Col>

                <Col xs={2}>
                    <Button className={"btn-sm shadow-none w-100"} style={{backgroundColor: "rgba(255,0,0, 0.6)"}}
                            onClick={() => changeCompletedAt({routeItem})}>
                        <FaUndo className={"mb-1"}/>
                    </Button>
                </Col>
            </Row>
        </ListGroup.Item>
    }

    const getUncompleteListGroupItem = ({routeItemIndex, routeItem}) => {
        return <ListGroup.Item
            key={routeItemIndex + 1}
            className={"mb-2 shadow-sm"}
        >
            <Row>
                <Col xs={10}>
                    <Badge className={"bg-orange"} pill>{routeItemIndex + 1}</Badge>

                    <small
                        className={"me-auto ms-2 font-13"}>{routeItem.customer.name ? routeItem.customer.name : routeItem.label}
                    </small>

                    {routeItem.customer.address &&
                        <Col xs={12}>
                            <small className={"text-light font-11"}>{routeItem.customer.address}</small>
                        </Col>
                    }

                    {routeItem.customer.phone_number &&
                        <Col xs={12}>
                            <a href={"tel:" + routeItem.customer.phone_number}
                               className={"text-light font-11"}>{routeItem.customer.phone_number}</a>
                        </Col>
                    }

                    {routeItem.customer.time_window &&
                        <Col xs={12}>
                            <small className={"text-light font-10"}>
                                <AiOutlineFieldTime/>{" "}
                                {toUtcHhMm(routeItem.customer.time_window.start)}
                                {" "}-{" "}
                                {toUtcHhMm(routeItem.customer.time_window.end)}
                                {routeItem.arrival &&
                                    <>, Arrival: {toUtcHhMm(routeItem.arrival)}</>
                                }
                            </small>
                        </Col>
                    }
                </Col>

                <Col xs={2}>
                    <Button className={"btn-sm btn-orange shadow-none w-100"}
                            onClick={() => navigationOnClick({routeItem})}>
                        <FiMapPin className={"mb-1"}/>
                    </Button>

                    <Button variant={"success"} className={"btn-sm shadow-none w-100 mt-2"}
                            onClick={() => changeCompletedAt({routeItem})}>
                        <AiOutlineCheck className={"mb-1"}/>
                    </Button>
                </Col>
            </Row>
        </ListGroup.Item>
    }

    return (
        spinnerMessage
            ? <CenterSpinner text={spinnerMessage}/>
            : route
                ?
                <div className={"solution-routes-container"}>
                    <Tabs
                        defaultActiveKey="list"
                        className={"fixed-top"}
                        justify
                        onSelect={(key) => onTabSelect(key)}
                        transition={false}
                    >
                        <Tab eventKey="list" title={<AiOutlineOrderedList/>} style={{marginTop: "60px"}}>
                            <Container fluid={true}>
                                <Heading text={route.vehicle.label}/>
                                <ListGroup className={"mt-3"}>
                                    {route.route_items
                                        .filter(routeItem => routeItem.customer)
                                        .map((routeItem, routeItemIndex) => {
                                            if (routeItemIsComplete(routeItem)) {
                                                return getCompleteListGroupItem({routeItemIndex, routeItem})
                                            } else {
                                                return getUncompleteListGroupItem({routeItemIndex, routeItem})
                                            }
                                        })
                                    }
                                </ListGroup>
                            </Container>
                        </Tab>

                        <Tab eventKey="map" title={<BsMap/>} style={{marginTop: "41px"}}>
                            <RouteMap route={route} setMap={setMap} bounds={bounds}/>
                        </Tab>

                        <Tab eventKey="settings" title={<FaCog/>} style={{marginTop: "60px"}}>
                            <Container fluid={true}>
                                <div className={"text-center"}>
                                    Logged in as:
                                    <p>{getUserName()}</p>
                                </div>

                                <div className={"text-center"}>
                                    <Button className={"btn-orange shadow-none mt-3"} style={{width: '110px'}}
                                            onClick={() => navigate(`/p${problemId}/s${solutionId}`)}>
                                        <BiArrowBack className={"me-2"} style={{marginBottom: '0.1rem'}}/>
                                        Routes
                                    </Button>
                                </div>

                                <div className={"text-center"}>
                                    <Button className={"btn-orange shadow-none mt-3"} style={{width: '110px'}}
                                            onClick={() => logout()}>
                                        <FaSignOutAlt className={"me-2"} style={{marginBottom: '0.1rem'}}/>
                                        Logout
                                    </Button>
                                </div>
                            </Container>
                        </Tab>
                    </Tabs>
                </div>
                :
                <NotAuthorized/>
    )
}

export default SolutionRoute