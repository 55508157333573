import React, {useState} from 'react'
import {ProgressBar} from 'react-bootstrap'

const RoutePlannerInProgress = ({index, solverRun}) => {
  const estimatedTimeToCompletionTimeUnixMs = solverRun.startTimeUnixMs + (solverRun.executionTime * 1000)

  const [progress, setProgress] = useState(0)
  const [message, setMessage] = useState('Optimizing..')
  const [completionMessage, setCompletionMessage] = useState(`The solver is set to finish:  ${new Date(estimatedTimeToCompletionTimeUnixMs).toLocaleString('nb-NO')}`)
  const [progressBarOptions, setProgressBarOptions] = useState({
    variant: 'primary',
    animated: true
  })

  const getCurrentProgress = () => {
    const currentTimeUnixMs = Date.now()
    const secondsSinceStart = (currentTimeUnixMs.valueOf() - solverRun.startTimeUnixMs) / 1000
    return (secondsSinceStart / solverRun.executionTime) * 100
  }

  const startProgress = () => {
    const intervalID = setInterval(() => {
      const currentProgress = getCurrentProgress()
      setProgress(currentProgress)

      if (currentProgress >= 100) {
        setMessage('Finished! Please refresh the page to retrieve solutions 🚀')
        setCompletionMessage('')
        setProgressBarOptions({
          variant: 'success',
          animated: false
        })
        clearInterval(intervalID)
      }
    }, 500)
  }

  startProgress()

  return (
        <div>
          <div className={'my-4'}>
            <h5>Route Planner {index + 1}</h5>
            <ProgressBar animated={progressBarOptions.animated} variant={progressBarOptions.variant}
                         now={progress}/>
            <p className='mt-3 mb-0'>{message}</p>
            <small>{completionMessage}</small>
          </div>
        </div>
  )
}

export default RoutePlannerInProgress
