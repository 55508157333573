import React, {useEffect, useState} from 'react'
import useAxiosPrivate from '../../adapters/xhr/axiosPrivate'
import {useParams} from 'react-router-dom'
import Heading from '../Heading'
import CenterSpinner from "../../utils/CenterSpinner";
import SolutionMap from "../Maps/SolutionMap";
import SEO from "../../utils/SEO";
import {Container} from "react-bootstrap";

const Solution = () => {
    const {problemId, solutionId} = useParams()
    const axiosPrivate = useAxiosPrivate()

    const [isLoading, setIsLoading] = useState(true)
    const [problem, setProblem] = useState(null)
    const [solution, setSolution] = useState(null)

    const fetchData = async () => {
        setIsLoading(true);

        const problemUrl = `/v1/problems/frontend/simple/${problemId}/`;
        const solutionUrl = `/v1/problems/${problemId}/solutions/frontend/${solutionId}/`;

        const responses = await Promise.all([axiosPrivate.get(problemUrl), axiosPrivate.get(solutionUrl)]).catch(error => {
            console.log(error)
            setIsLoading(false);
        })

        const problemData = await responses[0].data
        const solutionData = await responses[1].data

        setProblem(problemData)
        setSolution(solutionData)

        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <SEO title={"Solution"}/>
            {isLoading
                ? <CenterSpinner text={"Retrieving solution.."}/>
                : (problem && solution)
                    ? <>
                        <SolutionMap problem={problem} solution={solution}/>
                    </>
                    : <Container fluid={true} className={"mt-4"}>
                        <Heading text="Solution not found"/>
                        <p>{`No solution with id ${solutionId} found..`}</p>
                    </Container>
            }
        </>
    )
}

export default Solution
