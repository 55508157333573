import {Button, Container} from "react-bootstrap";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import React, {useContext} from "react";
import AuthContext from "../../contexts/AuthProvider";
import SEO from "../../utils/SEO";

const UserSettings = () => {
    const axiosPrivate = useAxiosPrivate()
    const {logout} = useContext(AuthContext)

    const handleLogoutAll = () => {
        axiosPrivate.post('/v1/logoutall/').then((response) => {
            if (response.status === 204) {
                logout()
            }
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    return (
        <>
            <SEO title={"Settings"}/>
            <Container fluid={true} className={"my-4"}>
                <Button className={"btn-orange shadow-none"} onClick={handleLogoutAll}>
                    Logout of all devices
                </Button>
            </Container>
        </>
    )
}

export default UserSettings