import {axiosPrivate} from './axios'
import {useContext, useEffect} from 'react'
import AuthContext from '../../contexts/AuthProvider'

const useAxiosPrivate = () => {
    const {getToken, logout} = useContext(AuthContext)

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config) => {
                const token = getToken()
                if (token) {
                    config.headers.Authorization = `Token ${token}`
                }
                return config
            },
            (error) => {
                Promise.reject(error).then(r => console.log(r.data))
            }
        )

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    logout()
                }
                return Promise.reject(error)
            }
        )

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept)
            axiosPrivate.interceptors.response.eject(responseIntercept)
        }
    }, [])

    return axiosPrivate
}

export default useAxiosPrivate
