import React from "react";

const NotAuthorized = () => {
    return (
        <div className={"h-100 d-flex flex-column align-items-center justify-content-center"}>
            <h5 className={"mt-3"}>You are not authorized to view this page..</h5>
        </div>
    )
}

export default NotAuthorized