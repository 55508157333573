import React, {useEffect, useRef, useState} from 'react'
import {FaCloudDownloadAlt, FaMapMarkedAlt, FaRocket} from "react-icons/fa";
import L from "leaflet";
import {Button, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../../adapters/xhr/axiosPrivate";

const MapOverlayProblemButtons = ({problem}) => {
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    const mapProblemButtonsStopPropagationRef = useRef(null);
    useEffect(() => {
        L.DomEvent.disableClickPropagation(mapProblemButtonsStopPropagationRef.current);
        L.DomEvent.disableScrollPropagation(mapProblemButtonsStopPropagationRef.current);
    }, [])

    const handleStartRoutePlanner = () => {
        const startTimeUnixMs = Date.now()
        setIsLoading(true)

        const url = `/v1/problems/${problem.id}/solve/`
        const formData = new FormData()
        formData.append('start_time_unix_ms', startTimeUnixMs)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axiosPrivate.post(url, formData, config).then((response) => {
            if (response.status === 200) {
                setErrorMessages([])
                navigate(`/problems/${problem.id}/solutions`)
            }
            setIsLoading(false)
        }).catch(error => {
            setErrorMessages(error.response.data.errors)
            setIsLoading(false)
        })
    }

    const downloadProblemJSON = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(problem.json, null, 2)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = problem.name + '.json';
        link.click();
    };

    return (
        <div ref={mapProblemButtonsStopPropagationRef} className={"map-problem-buttons-container"}>
            <Button size="sm" className={"btn-orange shadow-none map-problem-button mb-1"}
                    onClick={handleStartRoutePlanner} disabled={isLoading}>

                {isLoading ?
                    <Spinner animation="border" role="status" className={"mr-2"}
                             style={{width: '12px', height: '12px'}}>
                        <span className="visually-hidden"/>
                    </Spinner>
                    :
                    <FaRocket className="mr-2"/>
                }
                Solve
            </Button>

            <Button size="sm" className={"btn-orange shadow-none map-problem-button mb-1"}
                    onClick={() => navigate(`/problems/${problem.id}/solutions`)}>
                <FaMapMarkedAlt className="mr-2"/>Solutions
            </Button>

            <Button size="sm" className={"btn-orange shadow-none map-problem-button"}
                    onClick={() => downloadProblemJSON()}>
                <FaCloudDownloadAlt className="mr-2"/>Download
            </Button>
        </div>
    )
}

export default MapOverlayProblemButtons