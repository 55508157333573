import React from 'react'
import {Button, Container} from 'react-bootstrap'
import {FaRocket} from 'react-icons/fa'
import Heading from "../Heading";
import SEO from "../../utils/SEO";

const Dashboard = () => {
    const button = <Button
        className="shadow-sm btn-sm btn-orange"
        onClick={() => console.log('Generating Report..')}
    >
        <FaRocket className="text-white-50" style={{marginRight: '3.5px'}}/>
        Generate Report
    </Button>

    return (
        <>
            <SEO title={"Dashboard"}/>
            <Container fluid={true} className={"my-4"}>
                <Heading text="Dashboard"/>
            </Container>
        </>
    )
}

export default Dashboard
