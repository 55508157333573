import React, {useContext, useState} from 'react'
import LogoutModal from './LogoutModal'
import {Dropdown} from 'react-bootstrap'
import {FaBars, FaCog, FaSignOutAlt, FaUser, FaUserCircle, FaUsers} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import AuthContext from '../contexts/AuthProvider'

const Topbar = ({onSetSidebarToggle}) => {
    const navigate = useNavigate()
    const {getUserId, getUserName} = useContext(AuthContext)

    const [showLogoutModal, setShowLogoutModal] = useState(false)

    return (
        <>
            {/* Topbar */}
            <nav className="navbar navbar-expand topbar static-top"
                 style={{borderBottom: `0.10rem solid gainsboro`}}>
                <div className="container-fluid">
                    {/* <!-- Sidebar Toggle (Topbar) --> */}
                    <button
                        id="sidebarToggleTop"
                        className="btn btn-link rounded-circle"
                        onClick={() => onSetSidebarToggle()}
                    >
                        <FaBars/>
                    </button>

                    <div className="d-flex">
                        <Dropdown>
                            <Dropdown.Toggle id="user-dropdown-button" className="text-gray-600">
                                <span>{getUserName()}</span>
                                <FaUserCircle size={25} className={"ms-2 text-orange"}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => navigate(`/users/${getUserId()}/profile/`)}
                                >
                                    <FaUser className="text-gray-400 fa-sm fa-fw mr-2"
                                            style={{marginBottom: '0.1rem'}}/>
                                    Profile
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => navigate(`/users/${getUserId()}/drivers/`)}
                                >
                                    <FaUsers className="text-gray-400 fa-sm fa-fw mr-2"
                                             style={{marginBottom: '0.1rem'}}/>
                                    Drivers
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => navigate(`/users/${getUserId()}/settings/`)}
                                >
                                    <FaCog className="text-gray-400 fa-sm fa-fw mr-2" style={{marginBottom: '0.1rem'}}/>
                                    Settings
                                </Dropdown.Item>
                                <Dropdown.Divider className="dropdown-divider"/>
                                <Dropdown.Item onClick={() => setShowLogoutModal(true)}>
                                    <FaSignOutAlt className="text-gray-400 fa-sm fa-fw mr-2"
                                                  style={{marginBottom: '0.1rem'}}/>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </nav>

            {/* End of Topbar */}
            {showLogoutModal && <LogoutModal show={showLogoutModal} setShow={setShowLogoutModal}/>}
        </>
    )
}

export default Topbar
