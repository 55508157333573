import React, {createContext} from 'react'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'

const TOKEN = 'token'
const USER_ID = 'id'
const USER_NAME = 'name'
const USER_EMAIL = 'email'

const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate()

    const setTokenData = (tokenObj) => {
        if (tokenObj.token) {
            localStorage.setItem(TOKEN, tokenObj.token)
        }
        if (tokenObj.user) {
            localStorage.setItem(USER_ID, tokenObj.user.id)
            localStorage.setItem(USER_NAME, tokenObj.user.name)
            localStorage.setItem(USER_EMAIL, tokenObj.user.email)
        }
    }

    const getToken = () => localStorage.getItem(TOKEN)

    const getUserId = () => localStorage.getItem(USER_ID)

    const getUserEmail = () => localStorage.getItem(USER_EMAIL)

    const getUserName = () => localStorage.getItem(USER_NAME)

    const clearTokenData = () => {
        localStorage.removeItem(TOKEN)
        localStorage.removeItem(USER_ID)
        localStorage.removeItem(USER_EMAIL)
        localStorage.removeItem(USER_NAME)
    }

    const logout = () => {
        // Clear all token data from LocalStorage
        clearTokenData()
        // Redirect  the user to log in with action
        navigate("/login", {replace: true, state: {from: location}})
        return <Navigate to={"/login"} replace={true} state={{from: location}}/>
    }

    const contextData = {
        setTokenData,
        getToken,
        getUserId,
        getUserName,
        getUserEmail,
        clearTokenData,
        logout
    }

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
