import React, {useEffect, useRef} from 'react'
import {FaCloudDownloadAlt} from "react-icons/fa";
import L from "leaflet";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {IoShareOutline} from "react-icons/io5";

const MapOverlaySolutionButtons = ({problem, solution}) => {
    const navigate = useNavigate()

    const mapSolutionButtonsStopPropagationRef = useRef(null);
    useEffect(() => {
        L.DomEvent.disableClickPropagation(mapSolutionButtonsStopPropagationRef.current);
        L.DomEvent.disableScrollPropagation(mapSolutionButtonsStopPropagationRef.current);
    }, [])

    const getButtonWithTooltip = ({key, icon, onClickFn, disabled, tooltipText}) => {
        return (
            <OverlayTrigger
                key={key}
                placement={"right"}
                overlay={<Tooltip id={`solution-map-button-tooltip-${key}`}>{tooltipText}</Tooltip>}
            >
                <Button size="sm" className={"btn-orange shadow-none map-solution-button"} onClick={onClickFn}
                        disabled={disabled}>
                    {icon}
                </Button>
            </OverlayTrigger>
        );
    }

    const downloadSolutionJSON = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(solution.json, null, 2)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = solution.name + '.json';;
        link.click();
    };

    const shareRoutes = () => {
        navigate(`/problems/${problem.id}/solutions/${solution.id}/share`, {
            state: {
                problem: problem,
                solution: solution
            }
        })
    }

    return (
        <div className={"map-solution-buttons-container d-flex flex-column justify-content-between"}>
            <div ref={mapSolutionButtonsStopPropagationRef}
                 className={"d-flex justify-content-between"}>

                {getButtonWithTooltip({
                    key: 0,
                    icon: <IoShareOutline/>,
                    onClickFn: shareRoutes,
                    disabled: false,
                    tooltipText: "Share with drivers"
                })}

            </div>

            <div ref={mapSolutionButtonsStopPropagationRef} className={"d-flex justify-content-between"}>

                {getButtonWithTooltip({
                    key: 1,
                    icon: <FaCloudDownloadAlt/>,
                    onClickFn: downloadSolutionJSON,
                    disabled: false,
                    tooltipText: "Download solution as JSON"
                })}

            </div>
        </div>
    )
}

export default MapOverlaySolutionButtons