import {Button, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const MapOverlayHeader = ({problem, solution, header}) => {
    const navigate = useNavigate()

    const backToMap = () => {
        navigate(`/problems/${problem.id}/solutions/${solution.id}`, {
            state: {
                problem: problem,
                solution: solution
            }
        })
    }

    return (
        <div className={"map-overlay-header py-2"}>
            <Container fluid={true} className={"d-flex justify-content-between"}>
                <h5 className={"m-0"}>{header}</h5>
                <Button className={"btn-sm btn-orange shadow-none"} onClick={() => backToMap()}>Back to Map</Button>
                {/*<CloseButton onClick={() => setShowMap(!showMap)} />*/}
            </Container>
        </div>
    )
}

export default MapOverlayHeader