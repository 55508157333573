import React, {useContext, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {FaAngleDown, FaAngleLeft, FaAngleRight, FaHome, FaUpload} from 'react-icons/fa'
import {ImFilesEmpty} from 'react-icons/im'
import {Accordion, Button, Image} from 'react-bootstrap'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import AccordionContext from 'react-bootstrap/AccordionContext'
import LogoHorizontal from '../assets/images/logo/logo_horizontal_orange.png'
import Logo from '../assets/images/logo/logo_orange.png'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        icon: () => {
            return <FaHome/>
        },
        dividerAfter: true
    },
    {
        heading: 'Route planner',
        path: '/problems/upload',
        name: 'Upload   ',
        icon: () => {
            return <FaUpload/>
        },
        dividerAfter: true
    },
    {
        heading: 'Browse',
        path: '/problems',
        name: 'Problems',
        icon: () => {
            return <ImFilesEmpty/>
        },
        dividerAfter: true
    },
    // {
    //     heading: 'Interface',
    //     name: 'Settings',
    //     icon: () => {
    //         return <FaCog />
    //     },
    //     subRoutes: {
    //         heading: 'Custom Components:',
    //         data: [
    //             {
    //                 path: '/cards',
    //                 name: 'Collapse #1 '
    //             },
    //             {
    //                 path: '/buttons',
    //                 name: 'Collapse #2'
    //             }
    //         ]
    //     },
    //     dividerAfter: true
    // }
]

const Sidebar = ({
                     sidebarToggle,
                     setSidebarToggle,
                     onSetSidebarToggle,
                     getSidebarToggle
                 }) => {
    const location = useLocation()
    const [sidebarKey, setSidebarKey] = useState(0)
    const [defaultActiveKey, setDefaultActiveKey] = useState('')

    let sidebarClasses = ''
    sidebarClasses += sidebarToggle
        ? 'navbar-nav bg-gradient-sidebar sidebar accordion'
        : 'navbar-nav bg-gradient-sidebar sidebar accordion toggled'

    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerWidth < 496) {
                setSidebarToggle(false)
            } else if (getSidebarToggle()) {
                setSidebarToggle(true)
            }
        })
    })

    const CustomAccordionHeaderToggle = ({eventKey, callback, route}) => {
        const {activeEventKey} = useContext(AccordionContext)

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey)
        )

        const onAccordionHeaderClick = () => {
            if (defaultActiveKey !== route.name) {
                reloadSidebar(route.name)
            }
            decoratedOnClick()
        }

        const isCurrentEventKey = activeEventKey === eventKey

        return (
            <p
                className={"nav-link mb-0"}
                onClick={onAccordionHeaderClick}
            >
                {route.icon()}
                <span>{route.name}</span>
                {sidebarToggle &&
                    (
                        isCurrentEventKey
                            ? <FaAngleDown className="sidebar-collapse-link-icon"/>
                            : <FaAngleRight className="sidebar-collapse-link-icon"/>
                    )
                }
            </p>
        )
    }

    const isHeaderActive = (route) => {
        return route.subRoutes ? route.subRoutes.data.some(sr => sr.path === location.pathname) : route.path === location.pathname
    }

    const isSubRouteActive = (subRoute) => {
        return subRoute.path === location.pathname
    }

    // This is a hack to close all Accordion elements to close all open accordion items (since it's not supported yet).
    const reloadSidebar = (newAccordionDefaultActiveKey) => {
        setDefaultActiveKey(newAccordionDefaultActiveKey)
        setSidebarKey(sidebarKey === 0 ? 1 : 0)
    }

    return (
        <>

            {/*Start of Sidebar*/}
            <ul className={sidebarClasses} id="accordionSidebar" key={sidebarKey}>
                {routes.map((route, index) => {
                    return (
                        <div key={index}>
                            {route.heading && <div className="sidebar-heading">{route.heading}</div>}

                            <li
                                className={`nav-item${isHeaderActive(route) ? ' active' : ''}${route.dividerAfter ? '' : ' mb-0'}`}>
                                {
                                    route.subRoutes
                                        ? <>
                                            <Accordion key={index} defaultActiveKey={defaultActiveKey}>
                                                <Accordion.Item eventKey={route.name}>
                                                    <CustomAccordionHeaderToggle eventKey={route.name} route={route}/>
                                                    <Accordion.Body>
                                                        {
                                                            <div className="bg-white py-2 collapse-inner rounded">
                                                                <h6 className="collapse-header">{route.subRoutes.heading}</h6>
                                                                {
                                                                    route.subRoutes.data.map((subRoute, subRouteIndex) => {
                                                                        return <Link key={subRouteIndex} to={subRoute.path}
                                                                                     className={'text-decoration-none'}>
                                                                            <p className={`collapse-item${isSubRouteActive(subRoute) ? ' active' : ''}`}>
                                                                                {subRoute.name}
                                                                            </p>
                                                                        </Link>
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </>
                                        : <Link onClick={() => reloadSidebar('')} to={route.path}
                                                className={'text-decoration-none'}>
                                            <p className="nav-link mb-0">
                                                {route.icon()}
                                                <span>{route.name}</span>
                                            </p>
                                        </Link>
                                }
                            </li>

                            {route.dividerAfter && <hr className="sidebar-divider"/>}
                        </div>
                    )
                })}

                {/* <!-- Sidebar Toggler (Sidebar) --> */}
                <div className="text-center d-none d-md-inline">
                    <Button className={"rounded-circle border-0"} id="sidebar-toggle-button"
                            onClick={() => onSetSidebarToggle()}>
                        {sidebarToggle ? <FaAngleLeft id="sidebarToggleIcon"/> : <FaAngleRight id="sidebarToggleIcon"/>}
                    </Button>
                </div>


                <div
                    className={"sidebar-brand d-flex align-items-center justify-content-center mt-auto"}
                >
                    <Image src={Logo} width={32} className={"sidebar-logo"}/>
                    <Image src={LogoHorizontal} width={100} className={"sidebar-horizontal-logo"}/>
                </div>

            </ul>
            {/*End of Sidebar*/}
        </>
    )
}

export default Sidebar
