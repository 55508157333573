import React from 'react'
import 'leaflet/dist/leaflet.css'
import {MapContainer} from 'react-leaflet/MapContainer'
import {TileLayer} from 'react-leaflet/TileLayer'
import DepotMarker from './DepotMarker'
import {TileLayers} from './TileLayers'
import MapOverlayProblemSummary from "./MapOverlay/MapOverlayProblemSummary";
import {ZoomControl} from "react-leaflet";
import MapOverlayProblemButtons from "./MapOverlay/MapOverlayProblemButtons";
import {getProblemBounds, ORANGE_HSL} from "./Utils";
import ProblemCustomerMarker from "./ProblemCustomerMarker";

const ProblemMap = ({problem}) => {
    const bounds = getProblemBounds(problem)

    return (
        <MapContainer
            bounds={bounds}
            boundsOptions={{padding: [100, 100]}}
            scrollWheelZoom={true}
            attributionControl={false}
            zoomControl={false}
            className={"full-map-container"}
        >
            <TileLayer url={TileLayers.CartoDB_DarkMatter}/>
            <ZoomControl position={'topleft'}/>

            {
                problem.num_depots > 0 &&
                problem.depots.map((depot, depotIndex) => {
                    return <DepotMarker
                        key={depotIndex}
                        depot={depot}
                    />
                })
            }

            {problem.num_customers > 0 &&
                problem.customers.map((customer, customerIndex) => {
                    return <ProblemCustomerMarker
                        key={customerIndex}
                        customer={customer}
                        color={ORANGE_HSL}
                    />
                })}

            <MapOverlayProblemSummary problem={problem}/>
            <MapOverlayProblemButtons problem={problem}/>

        </MapContainer>
    )
}

export default ProblemMap
