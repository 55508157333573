import React, {useEffect, useState} from "react";
import Heading from "../Heading";
import {Container, ListGroup} from "react-bootstrap";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import CenterSpinner from "../../utils/CenterSpinner";
import SEO from "../../utils/SEO";

const UserDrivers = () => {
    const axiosPrivate = useAxiosPrivate()

    const [spinnerMessage, setSpinnerMessage] = useState("Retrieving drivers..")
    const [drivers, setDrivers] = useState(null)

    useEffect(() => {
        setSpinnerMessage("Retrieving drivers..")
        axiosPrivate.get(`/v1/user/drivers/`).then((response) => {
            if (response.status === 200) {
                setDrivers(response.data.drivers)
            }
            setSpinnerMessage(null)
        }).catch(_error => {
            setSpinnerMessage(null)
        })
    }, [])

    return (
        <>
            <SEO title={"Drivers"}/>
            {spinnerMessage
                ?
                <CenterSpinner text={spinnerMessage}/>
                :
                <Container fluid={true} className={"my-4"}>
                    <Heading text="Drivers"/>

                    <ListGroup className={"mt-3"}>
                        {drivers.map((driver, index) => {
                            return <ListGroup.Item action={false} key={index}>
                                <p className={"mb-0"}><small className={"text-light"}>Name:</small> {driver.name}</p>
                                <p className={"mb-0"}><small className={"text-light"}>Email:</small> {driver.email}</p>
                            </ListGroup.Item>
                        })}
                    </ListGroup>
                </Container>
            }
        </>
    )
}

export default UserDrivers